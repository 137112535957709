// Newsletter
const btnNewsletter = document.querySelector('.btnNewsletter');
const btnNewsletterM = document.querySelector('.btnNewsletterM');
const btnOverlay = document.getElementById("newsletter-overlay");
const btnCloseOverlay = document.querySelector('.closeDialog');

btnNewsletter.addEventListener('click', function(e) {
    e.preventDefault();
    const overlay = document.getElementById("newsletter-overlay");
    overlay.style.display = 'block';
});

btnCloseOverlay.addEventListener('click', function(e) {
    const overlay = document.getElementById("newsletter-overlay");
    overlay.style.display = 'none';
});

btnOverlay.addEventListener('click', function(e) {
    const overlay = document.getElementById("newsletter-overlay");
    overlay.style.display = 'none';
});

btnNewsletterM.addEventListener('click', function(e) {
    e.preventDefault();
    const overlay = document.getElementById("newsletter-overlay");
    overlay.style.display = 'block';
});
