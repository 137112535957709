/*
$(document).ready(function () {
    $('.nav-tabs > li > a').hover(function() {
          $(this).tab('show');
    });
});
*/

(function ($) {
	$(document).ready(function () {
        /*
        $('.nav-tabs > li > a').hover(function() {
              $(this).tab('show');
        });
        */

		// close Quick Cart Dialog
		const btnCloseQuickCart = document.getElementById("closeQuickCart");
        btnCloseQuickCart.addEventListener('click', function(e) {
            document.querySelector(".addcard-mobile").style.display = "none";
            document.querySelector(".addcard-box").style.display = "none";
            document.querySelector(".shop-btn").classList.remove('card-color');
        })

        const btnCloseQuickCartM = document.getElementById("closeQuickCartM");
        btnCloseQuickCartM.addEventListener('click', function(e) {
            document.querySelector(".addcard-mobile").style.display = "none";
        })

		// Scroll to Top
		jQuery('.scrollto-top').click(function () {
			jQuery('html').animate({ 'scrollTop': '0px' }, 400);
			return false;
		});

		jQuery(window).scroll(function () {
			var upto = jQuery(window).scrollTop();
			if (upto > 500) {
				jQuery('.scrollto-top').fadeIn();
			} else {
				jQuery('.scrollto-top').fadeOut();
			}
		});

		// headerfixed
		$(window).scroll(function () {
			var scroll = $(window).scrollTop();

			if (scroll >= 60) {
				$(".header-fixed").addClass("sticky-header");
			} else {
				$(".header-fixed").removeClass("sticky-header");
			}
		});

		/*$(window).scroll(function() {
			var scroll = $(window).scrollTop();

			if (scroll >= 66) {
			$(".mobile-fixedheader").addClass("sticky-headermbl");
			} else {
			$(".mobile-fixedheader").removeClass("sticky-headermbl");
			}
		});*/

		// toggle searchbar
		jQuery(".mobile-headright .search-btn").click(function () {
			jQuery(".toggle-searchbar").toggle()
			return false;
		});

		jQuery('.mobile-headright ul li .card-btn').click(function () {
			jQuery(".addcard-mobile").toggle()
			return false;
		});

		jQuery(".close-btnmbl button").click(function () {
			jQuery(this).parents(".addcard-mobile").toggle()
		});

		// toggle menu
		jQuery(".sidemenu-main ul li a").click(function () {
			jQuery(this).next().slideToggle()
			jQuery(this).children().toggleClass("arrowup")
		});

		jQuery('.mobile-headright ul li button').click(function () {
			jQuery(".side-menu").slideToggle()
		});

		jQuery(".menu-right .shop-btn").click(function () {
			jQuery(".addcard-box").toggle()
			jQuery(this).toggleClass("card-color")
		});

		jQuery(".filter-btn button").click(function () {
			jQuery(".catalog-left").toggle()
		});

		jQuery(".details-uprcnt ul li a").click(function () {
			jQuery(this).next().slideToggle()
			jQuery(this).children("img").toggleClass("arrow")
			return false;
		});

		jQuery(".close-btnc button").click(function () {
			jQuery(this).parents(".checkout-part").hide()
		});

		jQuery(".close-btnmbl button").click(function () {
			jQuery(this).parents(".checkout-mobilepart").hide()
		});

		jQuery(".coupon-codeitem button").click(function () {
			//jQuery(".coupon-codebox2").toggle()
			//jQuery(this).parents(".coupon-form").hide()
		});

		jQuery(".login-btn").click(function () {
			jQuery(".reg-form1").hide()
			jQuery(".reg-form2").show()
			return false;
		});

		jQuery(".filter-btn button").click(function () {
			jQuery(".catalog-right").hide()
		});

		jQuery(".editable-text h2 a").click(function() {
			jQuery(".editable-text input").attr('readonly', false);
			return false;
		});

		// selector border
		var selectorb = '.productsec-demoinner a';
		$(selectorb).on('click', function () {
			$(selectorb).removeClass('border');
			$(this).addClass('border');
		});

		jQuery('.podatki-item-inner-s1 ul li a').on('click', function () {
			jQuery(this).parent('li').addClass('active').siblings().removeClass('active');
			e.preventDefault();
		});


		// owlCarousel
		$("#owl-csel1").owlCarousel({
			items: 1,
			autoplay: true,
			autoplayTimeout: 6000,
			startPosition: 0,
			rtl: false,
			loop: true,
			margin: 15,
			dots: true,
			nav: true,
			navContainer: '.main-content .custom-nav',
			responsive: {
				0: {
					items: 1,
				},
				767: {
					items: 1,
				},
				1200: {
					items: 1,
				}
			}

		});

        $("#owl-csel2a").owlCarousel({
			center: false,
			items: 6,
			autoplay: false,
			autoplayTimeout: 3500,
			startPosition: 0,
			rtl: false,
			loop: true,
			margin: 8,
			dots: true,
			nav: true,
			navText: [
				'<img src="/assets/images/right.svg" alt="">',
				'<img src="/assets/images/left.svg" alt="">'
			],
			navContainer: '.main-content2a .custom-nav',
			responsive: {
				0: {
					center: true,
					items: 1.6,
					dots: false,
				},
				576: {
					center: true,
					items: 2,
					dots: false,
				},
				768: {
					items: 3,
				},
				992: {
					items: 5,
				},
				1200: {
					items: 6,
				}
			}

		});

		$("#owl-csel2").owlCarousel({
			center: false,
			items: 6,
			autoplay: false,
			autoplayTimeout: 3500,
			startPosition: 0,
			rtl: false,
			loop: true,
			margin: 8,
			dots: true,
			nav: true,
			navText: [
				'<img src="/assets/images/right.svg" alt="">',
				'<img src="/assets/images/left.svg" alt="">'
			],
			navContainer: '.main-content2 .custom-nav',
			responsive: {
				0: {
					center: true,
					items: 1.6,
					dots: false,
				},
				576: {
					center: true,
					items: 2,
					dots: false,
				},
				768: {
					items: 3,
				},
				992: {
					items: 5,
				},
				1200: {
					items: 6,
				}
			}

		});

		$("#owl-csel3").owlCarousel({
			center: false,
			items: 6,
			autoplay: false,
			autoplayTimeout: 3500,
			startPosition: 0,
			rtl: false,
			loop: true,
			margin: 8,
			dots: true,
			nav: true,
			navText: [
				'<img src="/images/right.svg" alt="">',
				'<img src="/images/left.svg" alt="">'
			],
			navContainer: '.main-content3 .custom-nav',
			responsive: {
				0: {
					center: true,
					items: 1.6,
					dots: false,
				},
				576: {
					center: true,
					items: 2,
					dots: false,
				},
				768: {
					items: 3,
				},
				992: {
					items: 5,
				},
				1200: {
					items: 6,
				}
			}

		});

		$("#owl-csel4").owlCarousel({
			center: true,
			items: 8,
			autoplay: true,
			autoplayTimeout: 3000,
			startPosition: 0,
			rtl: false,
			loop: true,
			margin: 15,
			dots: false,
			nav: true,
			navContainer: '.main-content4 .custom-nav',
			responsive: {
				0: {
					items: 3,
				},
				576: {
					items: 4,
				},
				768: {
					items: 6,
				},
				1200: {
					items: 8,
				}
			}

		});

		$("#owl-csel5").owlCarousel({
			center: true,
			items: 1,
			autoplay: false,
			autoplayTimeout: 3000,
			startPosition: 0,
			rtl: false,
			loop: true,
			margin: 15,
			dots: false,
			nav: true,
			URLhashListener: true,
			startPosition: 'URLHash',
			navContainer: '.main-content5 .custom-nav',
			responsive: {
				0: {
					items: 1,
					dots: true,
				},
				767: {
					items: 1,
					dots: true,
				},
				1200: {
					items: 1,
				}
			}

		});

		// marquee
		$('.marquee2').marquee({
			duration: 12000,
			pauseOnHover: true,
			gap: 20,
			duplicated: true
		});

		// marquee
		$('.marquee').marquee({
			duration: 20000,
			pauseOnHover: true,
			gap: 50,
			duplicated: true
		});

		// fancybox
		$('[data-fancybox="gallery"]').fancybox({
			thumbs: {
				autoStart: true

			}
		});



	});
})(jQuery);
