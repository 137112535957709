//Window.Vue = require('./plugins/vue.js'); // Debug
//Window.Vue = require('./plugins/vue.js'); // Production

window.$ = window.jQuery = require('./plugins/jquery-3.4.1.min.js');
require('./plugins/owl.carousel.js');
require('./plugins/light-box.js');
require('./plugins/bootstrap.bundle.js');
require('./plugins/jquery.marquee.min.js');
require('./plugins/jquery.pause.js');

import jBox from 'jbox';

// COMPONENTS
require('./components/scripts.js');
require('./components/newsletter.js');

document.addEventListener("DOMContentLoaded", () => {
    new jBox('Image');
});
